import React, { Component } from "react";

import {FieldError, UI} from 'src/engrator-core';

import { IntegrationModuleTabBar } from './../integration-module-tab-bar';
import {fetchPipelinesGroups} from "./rest-api";
import {TableGridData} from "../../../engrator-core/ui";
import {createPipelinesGroupsTableGridData} from "./pipelines-groups-tablegrid-data";

type Props = {};
type State = {
    error?: FieldError,
    isLoading: boolean
};

export class PipelinesGroupsPage extends Component<Props, State> {
    private tableGridData?: TableGridData = undefined;
    constructor(props: {}) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    componentDidMount(): void {
        this.setState({ isLoading: true}, () => {
            fetchPipelinesGroups()
                .then(groups => {
                    this.tableGridData = createPipelinesGroupsTableGridData(groups);
                    this.setState({ isLoading: false });
                })
                .catch(error => this.setState({ isLoading: false, error}));
        });
    }

    render() {
        return (
            <UI.Page className={`pipelines-page`}>
                <IntegrationModuleTabBar selectedIndex={ 0 } />
                { this.state.error && <UI.Message appearance={"error"}>{ this.state.error?.message}</UI.Message> }
                { this.state.isLoading && <UI.Loader visible={ true } appearance={"darkgray"}/> }
                { !this.state.isLoading && this.tableGridData && <React.Fragment>
                    <UI.TableGrid
                        data={ this.tableGridData!.data }
                        headers={ this.tableGridData!.headers }
                    />
                </React.Fragment> }
            </UI.Page>
        )
    }
}