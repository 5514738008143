import {PropertyDefinition} from "../../../../../generic/artifacts";
import {FilterField} from "./add-filter";

export enum FILTER_OPERATOR {
    'CONTAINS' = 'contains',
    'CONTAINS_ONE_OF' = 'contains one of',
    'EQUALS' = 'equals',
    // 'IS_EMPTY' = 'is empty',
    'NOT_CONTAINS' = 'not contains',
    'NOT_EQUALS' = 'not equals',
    // 'NOT_EMPTY' = 'is not empty',
    'MATCHES' = 'matches'
}
export enum FILTER_SET_NAME {
    ALL = 'all',
    NEW = 'newItems',
    UPDATED = 'synced'
}
export type NewFilter = Partial<ItemFilter>;
export type ItemFilter = {
    operator: FILTER_OPERATOR,
    value?: string;
    multiValues?: string[];
    field: FilterField
}
export type FiltersSet = { [key in FILTER_SET_NAME]: ItemFilter[] };

export function createEmptySet(): FiltersSet {
    return {
        [FILTER_SET_NAME.ALL]: [],
        [FILTER_SET_NAME.NEW]: [],
        [FILTER_SET_NAME.UPDATED]: []
    }
}

export function requiresValue(operator?: FILTER_OPERATOR): boolean {
    if (!operator) {
        return false;
    }
    // if (operator === FILTER_OPERATOR.IS_EMPTY
    // || operator === FILTER_OPERATOR.NOT_EMPTY) {
    //     return false;
    // }
    return true;
}