import React from "react";

import {FieldError, UI} from 'src/engrator-core';
import {FlowStep} from "../flow-steps-factory";
import {LogsViewer} from "../../../../engrator-core/ui";
import {fetchStepLogs} from "../rest-api";

type Props = {
    flowStep: FlowStep;
};
type State = {
    error?: FieldError,
    isLoading: boolean
};

export class Logs extends React.Component<Props, State> {
    private logs?: string;

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true
        }
    }

    componentDidMount(): void {
        fetchStepLogs(this.props.flowStep.flowId, this.props.flowStep.stepIndex)
            .then(logs => {
                this.logs = logs;
                this.setState({ isLoading: false, error: undefined });
            })
            .catch(error => { this.setState({ error, isLoading: false })});
    }

    render() {
        return <div className={`tab.logs`}>
            { this.state.error && <UI.Message appearance={"error"}>{ this.state.error?.message }</UI.Message> }
            { this.state.isLoading && <UI.Loader visible={ true } appearance={"darkgray" } /> }
            { this.logs && <LogsViewer logs={ this.logs } /> }
        </div>
    }
}