import React from 'react';

import {UI} from 'src/engrator-core';
import {DropdownOption, Icon20} from '../../../../../../engrator-core/ui';
import {SmartIntTrigger} from '../../../definition/smart-int-trigger.type';
import {PropertyMapping} from '../../property-mapping.type';
import {fetchValueMappingsOptions} from './value-mappings-options-fetcher';
import {getEmptyAdditionalData, PropertyValue, ValueMapping} from './index';
import {AddValueMapping} from './add-value-mapping';
import {SmartIntType} from '../../../definition/smart-int-type.type';
import {createValueMappingsGroups, getValueMappingForAnotherOption} from './value-mappings-group-factory';
import {ValueMappingsGroupContainer} from './value-mappings-group-container';
import {FormError} from '../../../../../../engrator-core/form-error.type';
import {DefaultMappingPicker} from './default-mapping-picker';
import {DefaultMapping} from './default-mappping.type';
import {ValueMappingsGroup} from './value-mappings-group.type';
import {EmptyHandler} from './empty';
import {FieldsMappingNavBarItemName, NavBar} from './nav-bar';
import {Formatters} from './formatters';
import {AppsSupport} from '../../visual-integration-designer';
import {PropertyDefinitionInfo} from './property-definition-info';
import {
    areSomeMappingsCopied,
    copyMappedOptions,
    reuseCopiedOptions
} from "../../../configuration/sharing/mappings-copy";
import {SharingAutoMapping} from "../../../configuration/sharing";
import {AutoMappedOptions} from "../../../configuration/sharing/automap-options";
import {FeatureFlag, isFeatureFlagEnabled} from "../../../../../top-navigation/rest-api";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {PropertyDataType, PropertyDefinition} from "../../../../../../generic/artifacts";
import {SmartIntDefinitionTypeMapping} from "../../../definition/smart-int-definition--type-mapping.type";

export type AddMappingOptions = { left: DropdownOption[]; right: DropdownOption[] };

type Props = {
    disableOptions?: boolean;
    groups: ValueMappingsGroup[];
    depth: number;
    // valueMappings: ValueMapping[];
    defaults: { left: DefaultMapping, right: DefaultMapping };
    empty: { left: string, right: string };
    onMappingsChangedHandler: (groups: ValueMappingsGroup[]) => void;
    mapping: PropertyMapping;
    closeHandler: () => void;
    leftTrigger: SmartIntTrigger;
    rightTrigger: SmartIntTrigger;
    typeMapping: SmartIntDefinitionTypeMapping;
    appsSupport: AppsSupport;
    showNavBar: boolean;
    valueConfigurationHandler?: (group: ValueMappingsGroup) => void;
} & RouteComponentProps;

type State = {
    showAutoMapping: boolean;
    isLoadingOptions: boolean;
    error?: FormError;
    addOptionToSide: '' | 'left' | 'right';
    groups: ValueMappingsGroup[];
    currentTab: FieldsMappingNavBarItemName;
};

class ValuesMappingsPicker extends React.Component<Props, State> {
    private leftOptions: DropdownOption[] = [];
    private rightOptions: DropdownOption[] = [];
    private addMappingsOptions: AddMappingOptions = {
        left: [],
        right: []
    };
    private propertyValueToAdd?: PropertyValue;

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoadingOptions: true,
            addOptionToSide: '',
            groups: this.props.groups,
            showAutoMapping: false,
            currentTab: FieldsMappingNavBarItemName.Options
        };
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.mapping.left.id !== this.props.mapping.left.id || prevProps.mapping.right.id !== this.props.mapping.right.id) {
            await this.fetchData();
        }
    }

    private async fetchData(): Promise<void> {
        await this.setState({ isLoadingOptions: true });
        fetchValueMappingsOptions(
            this.props.leftTrigger,
            this.props.typeMapping.left,
            this.props.rightTrigger,
            this.props.typeMapping.right,
            this.props.mapping
        ).then((result) => {
            this.leftOptions = result.left;
            this.rightOptions = result.right;
            const valueMappings: ValueMapping[] = [];
            this.props.groups.forEach(group => {
                group.valueMappings.forEach(value => valueMappings.push(value));
            });
            const groups = createValueMappingsGroups(valueMappings);
            this.leaveOnlyUnPickedOptions(groups);
            this.setState({isLoadingOptions: false}, () => {
            });
        }).catch(error => {
            this.setState({error, isLoadingOptions: false});
        });
    }

    render() {
        const isLoadingOptions = (this.state.isLoadingOptions);
        // const groups = createValueMappingsGroups(this.mappings);
        return <div className={`values-mapping`}>
            <div className={`inner`}>
                <div className={`flex row types-header`}>
                    <div className={`flex-equal`}>
                        {this.props.mapping.left.name}
                        <PropertyDefinitionInfo definition={this.props.mapping.left}/>
                    </div>
                    <div className={`flex-equal`}>
                        {this.props.mapping.right.name}
                        <PropertyDefinitionInfo definition={this.props.mapping.right}/>
                    </div>
                </div>
                {this.props.showNavBar && <NavBar
                    selectedItem={this.state.currentTab}
                    onChange={this.switchCurrentTab.bind(this)}
                />}
                {this.state.currentTab === FieldsMappingNavBarItemName.Formatters && <React.Fragment>
                    <Formatters
                        mapping={this.props.mapping}
                        appsSupport={this.props.appsSupport}
                    />
                </React.Fragment>}
                {this.state.currentTab === FieldsMappingNavBarItemName.Options && <React.Fragment>
                    { !this.props.disableOptions && <UI.FormSection
                        label={`When fields should be set up`}
                    >
                        <div className={`flex row-two-cols`}>
                            <div className={`left`}>
                                <UI.Checkbox
                                    label={`Set ${ this.props.mapping.left.name } when the ${ this.props.appsSupport.leftApp} (left) item is CREATED`}
                                    defaultValue={`${ this.props.mapping.options.left.onCreate }`}
                                    onChange={ () => this.changeOption('left', 'onCreate') } checkedValue={`true`} uncheckedValue={`false`} />
                                <UI.Checkbox
                                    label={`Set ${ this.props.mapping.left.name } when the ${ this.props.appsSupport.leftApp} (left) item is UPDATED`}
                                    defaultValue={`${ this.props.mapping.options.left.onUpdate }`}
                                    onChange={ () => this.changeOption('left', 'onUpdate') } checkedValue={`true`} uncheckedValue={`false`} />
                                { this.shouldShowPostCreateCheckbox(this.props.mapping.left) && <UI.Checkbox
                                    label={`Update '${ this.props.mapping.left.name }' with value from '${ this.props.mapping.right.name}' after the ${ this.props.appsSupport.rightApp} (right) item is CREATED`}
                                    defaultValue={`${ this.props.mapping.options.left.postCreate }`}
                                    onChange={ () => this.changeOption('left', 'postCreate') } checkedValue={`true`} uncheckedValue={`false`} /> }
                            </div>
                            <div className={`right info`}>
                                <UI.Checkbox
                                    label={`Set ${ this.props.mapping.right.name } when the ${ this.props.appsSupport.rightApp} (right) item is CREATED`}
                                    defaultValue={`${ this.props.mapping.options.right.onCreate }`}
                                    onChange={ () => this.changeOption('right', 'onCreate') } checkedValue={`true`} uncheckedValue={`false`} />
                                <UI.Checkbox
                                    label={`Set ${ this.props.mapping.right.name } when the ${ this.props.appsSupport.rightApp} (right) item is UPDATED`}
                                    defaultValue={`${ this.props.mapping.options.right.onUpdate }`}
                                    onChange={ () => this.changeOption('right', 'onUpdate') } checkedValue={`true`} uncheckedValue={`false`} />
                                { this.shouldShowPostCreateCheckbox(this.props.mapping.right) && <UI.Checkbox
                                    label={`Update '${ this.props.mapping.right.name }' with value from '${ this.props.mapping.left.name}' after the ${ this.props.appsSupport.leftApp} (left) item is CREATED`}
                                    defaultValue={`${ this.props.mapping.options.right.postCreate }`}
                                    onChange={ () => this.changeOption('right', 'postCreate') } checkedValue={`true`} uncheckedValue={`false`} /> }
                            </div>
                        </div>
                    </UI.FormSection> }
                    <br/><br/>
                    <div className={'row-two-cols mapped-options-header right-buttons'}>
                        <div className={`left`}>
                            <h2>Mapped options</h2>
                        </div>
                        <div className={`right`}>
                            { !isLoadingOptions && isFeatureFlagEnabled(FeatureFlag.IMPROVED_MAPPINGS) && <UI.ButtonsBar
                                secondary={[
                                    <UI.Button
                                        appearance={"secondary"}
                                        onClick={ () => this.goToNotifications() }
                                        text={`Create Alert`}
                                        icon={ <UI.Icon
                                            icon20={ Icon20.CirclePlusWhite}
                                        /> }
                                    />,
                                    <UI.Button
                                        appearance={"secondary"}
                                        onClick={ () => this.autoMappingClicked() }
                                        text={`Automatic Mapping`}
                                        icon={ <UI.Icon
                                            icon={'bolt'}
                                        /> }
                                    />,
                                    areSomeMappingsCopied() && <UI.Button
                                        appearance={"secondary"}
                                        onClickConfirmationText={`Pasted!`}
                                        onClick={ () => this.useCopiedOptions() }
                                        text={`Paste`}
                                        icon={ <UI.Icon
                                            icon={'paste'}
                                            // onClick={ () => this.useCopiedOptions() }
                                        /> }
                                    />,
                                    <UI.Button
                                        onClickConfirmationText={`Copied!`}
                                        appearance={"secondary"}
                                        onClick={ () => copyMappedOptions(this.state.groups) }
                                        text={`Copy`}
                                        icon={ <UI.Icon
                                            icon={'copy'}
                                        /> }
                                    />
                                ]}
                            /> }
                        </div>
                    </div>
                    <UI.Message appearance={"info"}>Set your mapping preferences in Getint to enable smooth translation and synchronization of options across apps. As different applications utilize unique IDs and labels, Getint adapts to these changes dynamically. The options can be later on added or removed. Stay updated with <UI.NavLink path={`/app/integration/notifications`}>Notifications</UI.NavLink> alerts whenever your option mappings require adjustments.</UI.Message>
                    {this.state.groups.map((group, index) => <ValueMappingsGroupContainer
                        key={index}
                        group={group}
                        addPropertyValue={this.addPropertyValue.bind(this)}
                        addMultipleToSide={this.state.addOptionToSide}
                        valueConfigurationHandler={this.props.valueConfigurationHandler}
                        onDeleteHandler={(mapping) => this.deleteMapping(group, mapping)}
                    />)}
                    {isLoadingOptions && <UI.Loader visible={true} appearance={'darkgray'}/>}
                    {this.state.error && <UI.Message appearance={'error-message'} message={'Failed to load fields options for mapping. ' + this.state.error.message}/>}
                    {!isLoadingOptions &&
                        <React.Fragment>
                            { !this.state.error && <AddValueMapping
                                appsSupport={ this.props.appsSupport }
                                optionsQueryChangeHandler={this.optionsQueryChangeHandler.bind(this)}
                                mapping={this.props.mapping}
                                onValueOptionSelected={this.onValueOptionSelected.bind(this)}
                                options={this.addMappingsOptions}
                                addMappingHandler={this.addMappingHandler.bind(this)}
                                triggers={ { left: this.props.leftTrigger, right: this.props.rightTrigger } }
                            /> }

                            <UI.FormSection
                                label={`Default mapping`}>
                                <div className={`row-two-cols default-mapping `}>
                                    <div className={`left`}>
                                        <DefaultMappingPicker options={this.leftOptions}
                                                              mapping={this.props.defaults.left}
                                                              onChangeHandler={() => {
                                                              }}/>
                                    </div>
                                    <div className={`right`}>
                                        <DefaultMappingPicker options={this.rightOptions}
                                                              mapping={this.props.defaults.right}
                                                              onChangeHandler={() => {
                                                              }}/>
                                    </div>
                                </div>
                            </UI.FormSection>

                            { (this.props.empty.left || this.props.empty.right) && <UI.FormSection
                                label={`Empty value handling (optional)`}>
                                <EmptyHandler
                                    empty={this.props.empty}/>
                            </UI.FormSection> }
                        </React.Fragment>
                    }
                </React.Fragment>}
                {/*<div className={`btns`}>*/}
                {/*    <UI.Button onClick={() => this.props.closeHandler()} text={`Close`} appearance={'secondary'}/>*/}
                {/*</div>*/}
                { this.state.showAutoMapping && <SharingAutoMapping
                    triggers={ { left: this.props.leftTrigger, right: this.props.rightTrigger} }
                    propertyMapping={ this.props.mapping }
                    typeMapping={ this.props.typeMapping }
                    options={ this.addMappingsOptions }
                    depth={ this.props.depth + 1 }
                    closeHandler={ (options?: AutoMappedOptions[], clear?: boolean) => this.onAutoMappingClose(options, clear) }
                /> }
            </div>
        </div>
    }

    private async onAutoMappingClose(options?: AutoMappedOptions[], clear?: boolean): Promise<void> {
        if (options) {
            if (clear) {
                await this.setState({ groups: [] });
            }
            for (const autoMappedOption of options) {
                if (autoMappedOption.approved) {
                    await this.addMappingHandler({
                        left: {
                            name: autoMappedOption.left.label,
                            value: autoMappedOption.left.value,
                            additionalData: getEmptyAdditionalData()
                        },
                        right: {
                            name: autoMappedOption.right.label,
                            value: autoMappedOption.right.value,
                            additionalData: getEmptyAdditionalData()
                        },
                        isDefault: false
                    });
                }
            }
        }
        this.setState({ showAutoMapping: false })
    }

    private useCopiedOptions() {
        const groups = reuseCopiedOptions();
        this.leaveOnlyUnPickedOptions(groups);
        this.setState({ groups }, () => {
            this.props.onMappingsChangedHandler(this.state.groups);
        });
    }
    private switchCurrentTab(tab: FieldsMappingNavBarItemName) {
        this.setState({currentTab: tab});
    }

    private optionsQueryChangeHandler(side: 'left' | 'right', queryValue: string) {
        // console.log(this.props.mapping, side, queryValue);
    }

    private onValueOptionSelected(side: 'left' | 'right', propertyValue: PropertyValue) {
        if (!propertyValue) {
            this.propertyValueToAdd = undefined;
            this.setState({addOptionToSide: ''});
        } else {
            this.propertyValueToAdd = propertyValue;
            this.setState({addOptionToSide: side});
        }
    }

    private addPropertyValue(side: 'left' | 'right', group: ValueMappingsGroup) {
        if (this.propertyValueToAdd) {
            const valueMapping = getValueMappingForAnotherOption(side, group, this.propertyValueToAdd);
            if (valueMapping) {
                this.addMappingHandler(valueMapping, group);
            }
        }
    }

    private changeOption(side: 'left' | 'right', prop: 'onCreate' | 'onUpdate' | 'postCreate') {
        this.props.mapping.options[side][prop] = !!!this.props.mapping.options[side][prop];
    }

    private addMappingHandler(mapping: ValueMapping, group?: ValueMappingsGroup): Promise<void> {
        return new Promise((resolve) => {
            const valueMappings: ValueMapping[] = [];
            this.state.groups.forEach(group => {
                group.valueMappings.forEach(value => valueMappings.push(value));
            });
            valueMappings.push(mapping);
            const groups = createValueMappingsGroups(valueMappings);
            this.leaveOnlyUnPickedOptions(groups);
            this.propertyValueToAdd = undefined;
            this.setState({addOptionToSide: '', groups}, () => {
                this.props.onMappingsChangedHandler(this.state.groups);
                resolve();
            });
        });
    }

    private deleteMapping(group: ValueMappingsGroup, mapping: ValueMapping) {
        const index = group.valueMappings.indexOf(mapping);
        group.valueMappings.splice(index, 1);
        const valueMappings: ValueMapping[] = [];
        this.state.groups.forEach(group => {
            group.valueMappings.forEach(value => valueMappings.push(value));
        });
        const groups = createValueMappingsGroups(valueMappings);
        this.leaveOnlyUnPickedOptions(groups);
        this.setState({groups}, () => {
            this.props.onMappingsChangedHandler(this.state.groups);
        });
    }

    private autoMappingClicked() {
        this.setState({ showAutoMapping: true });
    }

    private leaveOnlyUnPickedOptions(groups: ValueMappingsGroup[]) {
        this.addMappingsOptions = {left: [], right: []};
        this.leftOptions.forEach(option => {
            let exists = false;
            groups.forEach(group => {
                group.valueMappings.forEach(mapping => {
                    if (option.value === mapping.left.value) {
                        exists = true;
                    }
                });
            });
            if (!exists) {
                this.addMappingsOptions.left.push(option);
            }
        });
        this.rightOptions.forEach(option => {
            let exists = false;
            groups.forEach(group => {
                group.valueMappings.forEach(mapping => {
                    if (option.value === mapping.right.value) {
                        exists = true;
                    }
                });
            });
            if (!exists) {
                this.addMappingsOptions.right.push(option);
            }
        });
    }

    private goToNotifications() {
        (this.props as any).history.push(`/app/integration/notifications`);
    }

    private shouldShowPostCreateCheckbox(propertyDefinition: PropertyDefinition): boolean {
        const isReadonly = !!propertyDefinition.options?.readonly;
        const isDropdownField = propertyDefinition.dataType === PropertyDataType.IdLabel
            || propertyDefinition.dataType === PropertyDataType.IdLabelArray;
        return !isReadonly && !isDropdownField;
    }
}

export default withRouter(ValuesMappingsPicker);