import React from 'react';
import { AppSmartIntsSupport } from 'src/app/integration/smartints/app-smart-ints-support';

import { SmartIntTrigger } from 'src/app/integration/smartints/definition/smart-int-trigger.type';
import { DropdownOption } from '../../../engrator-core/ui';
import { FreshdeskSmartIntsEditTrigger } from 'src/software/freshdesk/smart-ints/freshdesk-smart-ints-edit-trigger';
import { FreshdeskSmartIntsTrigger } from 'src/software/freshdesk/smart-ints/freshdesk-smart-ints-trigger';
import {FreshserviceSmartIntsTrigger} from "./freshservice-smart-ints-trigger";

export class FreshserviceSmartintsSupport extends AppSmartIntsSupport {
    getTriggerConfigurationComponent(connectionId: number, onConfigured: (configuration: SmartIntTrigger) => void): any {
        return <FreshserviceSmartIntsTrigger
            connectionId={connectionId}
            onConfigured={onConfigured}
        />;
    }

    getEditTriggerConfigurationComponent(connectionId: number, trigger: SmartIntTrigger, onConfigured: (configuration: SmartIntTrigger) => void): any {
        return <FreshdeskSmartIntsEditTrigger
            connectionId={connectionId}
            onConfigured={onConfigured}
            trigger={trigger}
        />;
    }

    hasTriggerConfigurationComponent(): boolean {
        return false;
    }

    public getSubtasksRelationshipsOptions(trigger: SmartIntTrigger): Promise<DropdownOption[]> {
        return Promise.resolve([]);
    }
}
