import React from "react";
import {copyObject, FieldError, UI} from "src/engrator-core";
import {AppsSupport} from "../visual-integration-designer";
import {SmartIntDefinition} from "../../definition";
import {autoBuildIntegration, AutoBuildResult} from "../rest-api";
import {HelpCallButton} from "../../../../engagement/help-call-button";
import {FieldsSummary} from "../fields-manager/fields-summary";

type State = {
    loadingError?: any;
    error?: FieldError;
    isLoading: boolean;
    definition: SmartIntDefinition;
    stage: 'askToProceed' | 'autoBuilding' | 'finished' | 'finished-no-success' | 'error';
};

type Props = {
    closeHandler: () => void;
    applyHandler: (result?: SmartIntDefinition) => void;
    appSupport: AppsSupport;
    multiFieldsUsage: boolean;
    definition: SmartIntDefinition;
}

export class AutoBuilderWindow extends React.Component<Props, State> {
    private result?: AutoBuildResult;

    constructor(props: Props) {
        super(props);
        this.state = {
            stage: 'askToProceed',
            isLoading: false,
            definition: copyObject(this.props.definition)
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (JSON.stringify(this.props.definition) !== JSON.stringify(prevProps.definition)) {
            this.setState({ definition: copyObject(this.props.definition) });
        }
    }

    render() {
        const showPrimaryBtn = (this.state.stage === 'finished')
            ? true
            : false;
        const hideCloseBtn = (this.state.stage === 'finished')
            ? false
            : true;
        return <UI.FullScreenModal
            header={`Quick Build`}
            primaryBtnHandler={() => this.applyClicked()}
            showPrimaryBtn={showPrimaryBtn}
            hideCloseBtn={hideCloseBtn}
            maximized={true}
            primaryBtnText={`Apply`}
            closeBtnHandler={() => this.closeClicked()}
        >
            {this.state.error && <React.Fragment>
                <UI.Message appearance={'error-message'}>
                    {this.state.error.message}
                </UI.Message>
                <UI.ButtonsBar
                    primary={<UI.Button
                        appearance={`secondary`}
                        text={`Close`}
                        onClick={() => this.closeClicked()}
                    />}
                    secondary={[
                        <HelpCallButton/>
                    ]}
                />
            </React.Fragment>}
            {this.state.stage === 'askToProceed' && <React.Fragment>
                <UI.Message
                    appearance={'warning'}
                >
                    The Quick Build feature is currently in its Beta phase. Your feedback is crucial to our innovation. <a href={'https://getintio.atlassian.net/servicedesk/customer/portals'} target={'_blank'}>Contact us with your suggestions</a>.
                </UI.Message>
                <UI.Message appearance={"info"}>
                    Would you like to automatically build the integration? If an integration is already configured, it will be replaced.
                </UI.Message>
                <UI.ButtonsBar
                    primary={<UI.Button
                        isLoading={this.state.isLoading}
                        onClick={() => this.autoBuildIntegration()}
                        appearance={'elementary'}
                        text={`Build`}
                    />}
                    secondary={[
                        <UI.Button
                            appearance={`secondary`}
                            text={`Close`}
                            onClick={() => this.closeClicked()}
                        />
                    ]}
                />
            </React.Fragment>}
            {this.state.stage === 'finished' && <React.Fragment>
                <UI.Message appearance={"info"}>We have finished building your integration. Please review mapped fields.</UI.Message>
                {(this.result && this.result.summary.entries.length > 0) && <React.Fragment>
                    <UI.Message appearance={"warning"}>
                        Please address the following mapping issues
                        <ul>
                            {this.result.summary.entries.map((e, index) => <li key={index}>
                                {e.message}
                            </li>)}
                        </ul>
                        <p>At least one of the fields is of the 'choice' type. Please click the 'cog' icon to access details, map options, or configure field mapping. Currently, the data from these fields may not sync properly.</p>
                    </UI.Message>
                </React.Fragment>}
                {this.result?.definition && <FieldsSummary
                    shouldDisableAutoMap={ true }
                    appSupport={this.props.appSupport}
                    multiFieldsUsage={this.props.multiFieldsUsage}
                    definition={this.state.definition}
                />}
            </React.Fragment>}
            {this.state.stage === 'finished-no-success' && <React.Fragment>
                <UI.Message
                    appearance={"warning"}>
                    Unfortunately we could not automatically build integration for you. Please contact us to help you
                    with setting up integration.
                </UI.Message>
                <UI.ButtonsBar
                    primary={<UI.Button
                        appearance={`secondary`}
                        text={`Close`}
                        onClick={() => this.closeClicked()}
                    />}
                    secondary={[
                        <HelpCallButton/>
                    ]}
                />
            </React.Fragment>}
        </UI.FullScreenModal>
    }

    private async applyClicked(): Promise<boolean> {
        await this.props.applyHandler(this.result?.definition);
        return true;
    }

    private async closeClicked(): Promise<boolean> {
        this.props.closeHandler();
        return true;
    }

    private async reviewClicked(): Promise<void> {
        this.props.applyHandler(this.result?.definition);
    }

    private async autoBuildIntegration(): Promise<void> {
        await this.setState({isLoading: true});
        try {
            this.result = await autoBuildIntegration(this.props.definition)
            const definition = this.state.definition;
            definition.types = this.result.definition.types;
            // this.typesMappings = this.definition.types;
            // this.adjustApps();
            if (definition.types && definition.types.length > 0) {
                await this.setState({ definition, stage: 'finished'});
            } else {
                await this.setState({stage: 'finished-no-success'});
            }
        } catch (error) {
            await this.setState({error, stage: 'error'});
        }
    }
}