import React from 'react';
import { Icon } from './icon';
import {DocumentationLink} from "./documentation-link";

type Props = {
    appearance: 'success' | 'error' | 'error-message' | 'info' | 'warning',
    message?: any
};
type State = {
    message?: string;
    docLink?: string;
}
export class Message extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        const state = { message: '', docLink: '' };
        if (props.message) {
            if (props.message.message) {
                state.message = props.message.message;
            } else {
                state.message = props.message;
            }
            const regex = /{link='(.*)'}/gm;
            let m = regex.exec(state.message);
            if (m && m[1]) {
                state.message = state.message.replace(m[0], '');
                state.docLink = m[1];
            }
        }
        this.state = state;
    }

    render() {
        return <div className={`engrator-ui-message ${this.props.appearance}`}>
            { this.props.appearance === 'warning' && <Icon icon="exclamation-triangle" /> }
            { this.props.appearance === 'error-message' && <Icon icon="exclamation-triangle" /> }
            { this.state.message }
            { this.state.docLink && <DocumentationLink text={`Read more here`} url={ this.state.docLink } /> }
            { this.props.children }
        </div>
    }
}
