import PropertyDataType from './property-data-type';

export type PropertyOptions = {
    selectable: boolean;
    internalType: string;
    internalFieldId?: string;
    readonly?: boolean;
};

export type PropertyDefinition = {
    id: string,
    name: string,
    dataType: PropertyDataType,
    options?: PropertyOptions;
};

export const PredefinedPropertyDefinition: PropertyDefinition = {
    dataType: PropertyDataType.String,
    id: '_predefined',
    name: '_predefined',
    options: undefined
};
