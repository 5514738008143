import React, {Component, useEffect} from 'react';
import {copyObject, UI} from 'src/engrator-core';
import {FormError} from '../../../engrator-core/form-error.type';
import {Footer} from '../../footer';
import {useGlobalState} from "../../global-store";
import {getSystemVerticalMenu} from "../system-vertical-menu";
import {MainMenuItem} from "../../global-store/create-global-state";
import {
    DataStorageSettings,
    fetchSettings, getEmptyDataStorageSettings,
    getMinimumLogLevelOptions,
    saveSettings,
    SettingsGroupName
} from "./rest-api";

type State = {
    isLoading: boolean;
    error?: FormError;
    isSaving: boolean;
    saved: boolean;
    settings?: DataStorageSettings;
    hasChanged: boolean;
};

export class DataStoragePageCmp extends Component<{}, State> {
    private originalSettings?: DataStorageSettings;
    constructor(props: {}) {
        super(props);
        this.state = {
            isLoading: true,
            isSaving: false,
            hasChanged: false,
            saved: false
        };
    }

    async componentDidMount() {
        try {
            const settings = await fetchSettings(getEmptyDataStorageSettings(), SettingsGroupName.DataStorage) as DataStorageSettings;
            this.originalSettings = copyObject(settings);
            this.setState({isLoading: false, settings});
        } catch (error) {
            this.setState({ isLoading: false, error})
        }
    }

    render() {
        return (
            <UI.Page>
                <UI.Tile>
                    { this.state.error && <UI.Message appearance={"error-message"} message={ this.state.error.message} /> }
                    { this.state.isLoading && <UI.Loader visible={ true } appearance={"darkgray"}/> }
                    { this.state.settings && <UI.Form useMaxWidthClass={true}>
                        <UI.FormGroup
                            label={`Enable logs storage`}
                            isRequired={ true }
                            description={`Decide whether to store records of integration activities. Enables troubleshooting and historical review when active.`}
                        >
                            <UI.Checkbox
                                defaultValue={`${ this.state.settings.Enabled }`}
                                onChange={ (newValue: string) => this.changeSetting('Enabled', newValue) }
                                checkedValue={`true`}
                                uncheckedValue={`false`}
                            />
                        </UI.FormGroup>
                        { this.state.settings.Enabled === 'true' && <React.Fragment>
                            <UI.FormGroup
                                label={`Logs retention period`}
                                isRequired={ true }
                                description={`Set the duration for storing integration logs. Older logs are automatically deleted after the defined period.`}
                            >
                                <UI.Input
                                    defaultValue={`${ this.state.settings.Retention }`}
                                    type={'number'}
                                    placeholder={`Provide number of days`}
                                    onChange={ (newValue: string) => this.changeSetting('Retention', newValue) }
                                />
                                { this.state.settings.Retention && parseInt(this.state.settings.Retention) < 3 && <UI.Message appearance={'warning'}>
                                    Setting up shorter retention period may affect further troubleshooting.
                                </UI.Message> }
                            </UI.FormGroup>
                            <UI.FormGroup
                                label={`Logs minimum level`}
                                isRequired={ true }
                                description={`Defines the threshold for the severity level of log messages that should be recorded.`}
                            >
                                <UI.Dropdown
                                    defaultValue={`${ this.state.settings.MinimumLogLevel }`}
                                    options={ getMinimumLogLevelOptions() }
                                    onChange={ (newValue: string) => this.changeSetting('MinimumLogLevel', newValue) }
                                />
                            </UI.FormGroup>
                            <UI.FormGroup
                                label={`Log HTTP requests`}
                                isRequired={ false }
                                description={`Activate HTTP request and response logging for enhanced debugging. WARNING! Logging will remain active for only 10 minutes after saving.`}
                            >
                                <UI.Checkbox
                                    defaultValue={`${ this.state.settings.LogHttpRequests }`}
                                    onChange={ (newValue: string) => this.changeSetting('LogHttpRequests', newValue) }
                                    checkedValue={`true`}
                                    uncheckedValue={`false`}
                                />
                            </UI.FormGroup>
                        </React.Fragment> }
                        <UI.FormGroup
                            label={`Items data encryption in logs files`}
                            isRequired={ true }
                            description={`Choose to encrypt sensitive data within logs. Enhances security by ensuring confidential information remains protected.`}
                        >
                            <UI.Checkbox
                                defaultValue={`${ this.state.settings.ItemsDataEncryptionInLogs }`}
                                onChange={ (newValue: string) => this.changeSetting('ItemsDataEncryptionInLogs', newValue) }
                                checkedValue={`true`}
                                uncheckedValue={`false`}
                            />
                        </UI.FormGroup>
                        <UI.ButtonsBar
                            secondary={ [(this.state.saved) ? <UI.Message appearance={"success"}>Settings saved!</UI.Message> : <React.Fragment />] }
                            primary={ <UI.Button
                                // disabled={ !this.state.hasChanged } - removing this so user can save storage settings always which will make http requests logged for extended time
                                disabled={ this.state.isSaving }
                                text={`Save`}
                                onClick={ () => this.saveClicked() }
                                isLoading={ this.state.isSaving }
                            /> }
                        />
                    </UI.Form> }
                </UI.Tile>
                <Footer/>
            </UI.Page>
        );
    }

    private changeSetting(key: keyof DataStorageSettings, newValue: string) {
        if (this.state.settings && !this.state.isSaving) {
            const settings = this.state.settings;
            settings[key] = newValue;
            const hasChanged = JSON.stringify(settings) !== JSON.stringify(this.originalSettings);
            this.setState({settings, hasChanged});
        }
    }

    private async saveClicked() {
        try {
            await this.setState({saved: false, isSaving: true, error: undefined});
            await saveSettings(SettingsGroupName.DataStorage, this.state.settings!);
            this.originalSettings = copyObject(this.state.settings);
            await this.setState({hasChanged: false, saved: true, isSaving: false});
        } catch (error) {
            this.setState({ error, isSaving: false });
        }
    }
}
export function DataStoragePage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        setMainMenuItem(MainMenuItem.SystemSettings);
        setMenu(getSystemVerticalMenu());
    }, []);
    return <DataStoragePageCmp />
}