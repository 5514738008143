import React from 'react';
import { UI } from 'src/engrator-core';
import { FormError } from '../../../engrator-core/form-error.type';
import { DropdownOption } from '../../../engrator-core/ui';
import { fetchAllSmartIntsIntegrations } from '../../integration/smartints/pages/list/rest-api';
import { importSyncedArtifact } from './rest-api';

type Props = {
    close: () => void;
}
type State = {
    error?: FormError;
    integrationsOptions?: DropdownOption[];
    isLoading: boolean;
    isImporting: boolean;
    importData: ImportData;
    isSuccess: boolean;
};
export type ImportData = {
    integrationId?: number;
    captureData?: string;
    side?: string;
    itemsList?: string;
    file?: any;
};

export class ImportPopup extends React.Component<Props, State> {
    private sideOptions: DropdownOption[] = [
        { label: 'Left', value: 'Left' },
        { label: 'Right', value: 'Right' },
    ]

    constructor(props: Props) {
        super(props);
        this.state = {
            isImporting: false,
            isLoading: false,
            importData: {},
            isSuccess: false,
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true }, () => {
            fetchAllSmartIntsIntegrations()
                .then(list => {
                    const integrationsOptions = list.data.map((integration: any) => ({
                        value: integration.id,
                        label: integration.name
                    }));
                    this.setState({isLoading: false, integrationsOptions, error: undefined});
                })
                .catch(() => {

                });
        });
    }

    render() {
        return <UI.FullScreenModal
            header={`Import already paired items`}
            primaryBtnHandler={ () => this.import() }
            closeBtnHandler={ () => this.props.close() } >
            <div className={`import-popup`}>
            <div className={`inner`}>
                {this.state.isSuccess && <UI.Message appearance="success">
					Paired artifacts imported successfully !
				</UI.Message>}
                <UI.Form>
                    <UI.Message appearance={"info"}>
                        Importing already paired items (e.g. when switching to Getint from other tool)
                    </UI.Message>
                    <UI.FormGroup
                        label={`Select integration for which import pairs`}
                        isRequired={true}
                    >
                        {this.state.isLoading && <UI.Loader appearance={'darkgray'} visible={true}/>}
                        {!this.state.isLoading && this.state.integrationsOptions !== undefined && <UI.Dropdown
                            options={ this.state.integrationsOptions! }
                            onChange={ (value: any) => this.setValue('integrationId', value) }
                        /> }
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={`Which side of integration, first column represents?`}
                        isRequired={ true }
                    >
                        <UI.Dropdown
                            options={ this.sideOptions }
                            onChange={ (value: any) => this.setValue('side', value) }
                        />
                    </UI.FormGroup>
                        <UI.FormGroup
                            label={`Automatically capture data of imported items`}
                            description={`If selected, data capturing will be performed during first run of integration. Condition is that JIRA must be left side app in the integration.`}
                            isRequired={ false }
                        >
                            <UI.Checkbox
                                uncheckedValue={`false`}
                                checkedValue={`true`}
                                onChange={ (value: any) => this.setValue('captureData', value) }
                            />
                        </UI.FormGroup>
                    <UI.FormGroup
                        label={`Paste related items ids list. Each line should have comma separated items IDs.`}
                        isRequired={ true }
                    >
                        <br/>
                        <UI.Textarea
                            rows={ 10 }
                            onChange={ (value) => this.setValue('itemsList', value) }
                        />
                    </UI.FormGroup>
                    { this.state.error && <UI.Message appearance={"error"}>{ this.state.error?.general || this.state.error?.message }</UI.Message> }
                </UI.Form>
                { this.state.isImporting && <p>
                    <UI.Loader visible={ true } appearance={"darkgray"} />
                    Please wait while importing
                </p> }
            </div>
        </div>
        </UI.FullScreenModal>;
    }

    private isFormValid(): boolean {
        return !!(this.state.importData.itemsList
            && this.state.importData.integrationId
            && this.state.importData.side);
    }

    private setValue(key: 'itemsList' | 'side' | 'integrationId' | 'captureData', value: any) {
        const importData = this.state.importData;
        importData[key] = value;
        this.setState({ importData });
    }

    private import(): Promise<boolean> {
        if (!this.isFormValid()) {
            this.setState({ error: { general: 'Please fill all required fields'}});
            return Promise.resolve(false);
        }
        return new Promise((resolve, reject) => {
            this.setState({isImporting: true, isSuccess: false}, () => {
                importSyncedArtifact(this.state.importData)
                    .then(() => {
                        this.setState({isImporting: false, isSuccess: true});
                        resolve(true);
                    })
                    .catch((error) => {
                        console.log(error);
                        resolve(false);
                        this.setState({isImporting: false, error});
                    });
            });
        });
    }
}
