import React from 'react';
import {EntityFormHelper, FieldError, UI} from "src/engrator-core";
import {FormError} from "src/engrator-core/form-error.type";
import {DropdownOption} from "src/engrator-core/ui";
import { fetchProjects, fetchProjectIssueTypes } from "./../rest-api";
import {JiraProjectDTO} from "./jira-project-dto.type";
import {JiraIssueTypeDTO} from "./jira-issue-type-dto.type";

export type JiraProjectIssuetypePickerData = {
    project?: JiraProjectDTO,
    issueType?: JiraIssueTypeDTO
};

type Props = {
    connectionId: number;
    onDataChanged: (data: JiraProjectIssuetypePickerData) => void;
    formError?: FormError;
    configureIssueType?: boolean;
}

type State = {
    formError: FormError;
    isLoadingProjects: boolean;
    isLoadingIssueTypes: boolean;
}

export class JiraProjectIssuetypePicker extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private projectOptions: DropdownOption[] = [];
    private issueTypeOptions: DropdownOption[] = [];
    private data : JiraProjectIssuetypePickerData = {};
    private configureIssueType: boolean;

    constructor(props: Props) {
        super(props);
        this.state = {formError: {}, isLoadingProjects: true, isLoadingIssueTypes: false};
        this.configureIssueType = this.props.configureIssueType !== false;
        this.entityFormHelper = new EntityFormHelper((propertyName: 'project' | 'issueType', newValue, additionalArgument?: any) => {
            if (!additionalArgument) {
                return;
            }
            if (propertyName === 'project') {
                console.log(newValue);
                this.data.project = additionalArgument as JiraProjectDTO
                this.data.issueType = undefined;
                if (this.configureIssueType) {
                    this.fetchIssueTypes();
                }
            } else if (propertyName === 'issueType') {
                this.data.issueType = additionalArgument as JiraIssueTypeDTO;
            }
            this.props.onDataChanged(this.data);
            this.forceUpdate();
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.forceUpdate();
        }
    }

    componentDidMount(): void {
        fetchProjects(this.props.connectionId)
            .then((projects) => {
                this.projectOptions = projects.map(project => ({
                    label: project.name,
                    value: project.key,
                    object: project
                }));
                this.setState({isLoadingProjects: false});
            })
            .catch((error: FieldError) => {
                this.setState({ isLoadingProjects: false, formError: { ['pickerError']: error.message  } });
            });
    }

    render() {
        return <React.Fragment>
            { this.state.formError.pickerError && <UI.Message appearance={"error"}>{ this.state.formError.pickerError}</UI.Message> }
            <UI.FormGroup
                label={`Project`}
                error={ this.props.formError?.project }
                description={``}
                dataSel={`project`}
                isRequired={true}>
                { this.state.isLoadingProjects && <UI.Loader visible={true} appearance={"darkgray"} /> }
                { !this.state.isLoadingProjects && <UI.Dropdown options={ this.projectOptions } onChange={ this.entityFormHelper.onChangeHandler('project')   }/> }
            </UI.FormGroup>
            { this.data.project && this.configureIssueType && <UI.FormGroup
                label={`Issue Type`}
                error={ this.props.formError?.issueType }
                description={``}
                isRequired={ true }>
                { this.state.isLoadingIssueTypes && <UI.Loader visible={true} appearance={"darkgray"} /> }
                { !this.state.isLoadingIssueTypes &&
                <UI.Dropdown defaultValue={this.data.issueType?.id + ''} options={this.issueTypeOptions}
                             onChange={this.entityFormHelper.onChangeHandler('issueType')}/>
                }
            </UI.FormGroup> }
        </React.Fragment>;
    }

    private fetchIssueTypes() {
        if (!this.data.project) {
            return;
        }
        this.setState({ isLoadingIssueTypes: true }, () => {
            fetchProjectIssueTypes(this.props.connectionId, this.data.project!.key)
                .then((issueTypes) => {
                    this.issueTypeOptions = issueTypes.map(issueType => ({
                        label: issueType.name,
                        value: String(issueType.id),
                        object: issueType
                    }));
                    this.setState({isLoadingIssueTypes: false});
                })
                .catch((error: FieldError) => {
                    this.setState({ isLoadingIssueTypes: false, formError: {[`pickerError`]: error.message}});
                });
        });
    }
};