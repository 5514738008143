import React from 'react';
import { UI } from 'src/engrator-core';
import { SmartIntDefinitionTypeMapping } from '../../../definition/smart-int-definition--type-mapping.type';
import { SmartIntHierarchyConfiguration } from '../../../definition/smart-int-definition-configuration.type';
import { SmartIntTrigger } from '../../../definition/smart-int-trigger.type';
import { AppsSupport } from '../../visual-integration-designer';
import { RelationshipPicker, RelationshipPickerType } from './relationship-picker';

type Props = {
  appsSupport: Readonly<AppsSupport>;
  mapping: Readonly<SmartIntDefinitionTypeMapping>;
  hierarchyConfiguration: SmartIntHierarchyConfiguration;
  leftTrigger: SmartIntTrigger;
  rightTrigger: SmartIntTrigger;
};

export class EpicsSync extends React.Component<Props> {
    constructor(props: Readonly<Props> | Props) {
        super(props);
    }

    render() {
        return <UI.Form errors={ [] }>
            <UI.FormSection
                label={`Synchronize Epic relationship`}
            >

                <UI.FormGroup
                    label={<UI.Checkbox
                        defaultValue={`${this.props.hierarchyConfiguration.epics.enabled}`}
                        checkedValue={`true`}
                        uncheckedValue={`false`}
                        onChange={ this.switchEnabledFlag.bind(this) }
                        label={`Enable`}
                    />}
                    description={`Enable synchronization of Epic relationships for the currently configured type mapping. If item on one side will be linked to Epic item, getint.io will try to establish it for the counterpart item.`}
                >
                    { this.props.hierarchyConfiguration.epics.enabled && <UI.FormGroup
                        label={`Configure relationship`}
                        isRequired={ true}
                        description={`Configure how the relationship for Epics should be established for each app`}
                    >
                        <div className="flex row two-columns">
                            <div>
                                <RelationshipPicker
                                    pickerType={ RelationshipPickerType.Epics }
									appSupport={this.props.appsSupport.left}
									trigger={this.props.leftTrigger}
									label={`${this.props.appsSupport.leftApp} ${this.props.mapping.left.name} (left)`}
									onChange={ (option) => this.setOption('left', option) }
									defaultValue={ this.props.hierarchyConfiguration.epics.options.left }
                                />
                            </div>
                            <div>
								<RelationshipPicker
                                    pickerType={ RelationshipPickerType.Epics }
									appSupport={this.props.appsSupport.right}
									trigger={this.props.rightTrigger}
									label={`${this.props.appsSupport.rightApp} ${this.props.mapping.right.name} (right)`}
									onChange={(option) => this.setOption('right', option)}
									defaultValue={this.props.hierarchyConfiguration.epics.options.right}
								/>
                            </div>
                        </div>
                    </UI.FormGroup> }
                </UI.FormGroup>
            </UI.FormSection>
        </UI.Form>
    }

    private switchEnabledFlag() {
        this.props.hierarchyConfiguration.epics.enabled = !this.props.hierarchyConfiguration.epics.enabled;
        this.forceUpdate(); 
    }

    private setOption(side: 'left' | 'right', optionValue: string) {
        this.props.hierarchyConfiguration.epics.options[side] = optionValue;
    }
}
