import { DropdownOption } from 'src/engrator-core/ui';
import { SmartIntTrigger } from './definition/smart-int-trigger.type';

export abstract class AppSmartIntsSupport {
    abstract getTriggerConfigurationComponent(connectionId: number, onConfigured: (configuration: SmartIntTrigger) => void): any;
    abstract getEditTriggerConfigurationComponent(connectionId: number, trigger: SmartIntTrigger, onConfigured: (configuration: SmartIntTrigger) => void): any;
    abstract hasTriggerConfigurationComponent(): boolean;

    public fetchSmartIntTypes() {
    }

    public validateTriggerStep() {
    }

    public isSupportingHierarchyEpics(): boolean {
        return true;
    }

  public getEpicRelationshipsOptions(trigger: SmartIntTrigger): Promise<DropdownOption[]> {
    return Promise.resolve([]);
  }

  public getSubtasksRelationshipsOptions(trigger: SmartIntTrigger): Promise<DropdownOption[]> {
    return Promise.resolve([]);
  }

  public supportsAttachmentsStorage(): boolean {
    return false;
  }

}
