import React from "react";
import {copyObject, FieldError, UI} from "src/engrator-core";
import {AppsSupport} from "../visual-integration-designer";
import {AutoBuildResult, autoMapFields} from "../rest-api";
import {HelpCallButton} from "../../../../engagement/help-call-button";
import {SmartIntTrigger} from "../../definition/smart-int-trigger.type";
import {SmartIntDefinitionTypeMapping} from "../../definition/smart-int-definition--type-mapping.type";
import {PropertyMapping} from "../property-mapping.type";
import {FieldsMappings} from "../type-mapping-configuration/fields-mappings";

type State = {
    loadingError?: any;
    error?: FieldError;
    isLoading: boolean;
    stage: 'asked' | 'inProgress' | 'finished' | 'error';
    mapping: SmartIntDefinitionTypeMapping;
};

type Props = {
    closeHandler: () => void;
    applyHandler: (properties: PropertyMapping[], mode: 'merge' | 'replace') => void;
    appSupport: AppsSupport;
    leftTrigger: SmartIntTrigger;
    rightTrigger: SmartIntTrigger;
    multiFieldsUsage: boolean;
    mapping: SmartIntDefinitionTypeMapping;
    // definition: SmartIntDefinition;
}

export class MatchFieldsWindow extends React.Component<Props, State> {
    private result?: AutoBuildResult;

    constructor(props: Props) {
        super(props);
        this.state = {
            mapping: copyObject(this.props.mapping),
            isLoading: false,
            stage: 'asked',
        };
    }

    async matchFieldsClicked(): Promise<void> {
        await this.setState({ isLoading: true });
        try {
            this.result = await autoMapFields(this.props.leftTrigger, this.props.rightTrigger, this.props.mapping);
            let properties: PropertyMapping[] = [];
            const mapping = this.state.mapping;
            if (this.result.definition.types[0]) {
                // There should be just one type mapped
                properties = this.result.definition.types[0].propertiesConfiguration.properties;
                mapping.propertiesConfiguration.properties = properties;
                mapping.hierarchyConfiguration = this.result.definition.types[0].hierarchyConfiguration;
                mapping.statusTransitionConfiguration = this.result.definition.types[0].statusTransitionConfiguration;
                if (properties && properties.length > 0) {
                    // await this.leaveOnlyUnPickedOptions(properties);
                    // this.props.onMappingsChangedHandler(this.state.mappings);
                }
            }
            await this.setState({ stage: 'finished', mapping });
        } catch (e) {
            await this.setState({ stage: 'error', error: e });
        } finally {
            await this.setState({ isLoading: false });
        }
    }

    render() {
        const showPrimaryBtn = false;
        const hideCloseBtn = true;
        return <UI.FullScreenModal
            depth={1}
            header={`Match fields`}
            primaryBtnHandler={() => this.applyClicked()}
            showPrimaryBtn={showPrimaryBtn}
            hideCloseBtn={hideCloseBtn}
            maximized={true}
            primaryBtnText={`Apply`}
            closeBtnHandler={() => this.closeClicked()}
        >
            {this.state.stage === 'error' && <React.Fragment>
                <UI.Message appearance={'error-message'} message={this.state.error} />
                <UI.ButtonsBar
                    primary={<UI.Button
                        appearance={`secondary`}
                        text={`Close`}
                        onClick={() => this.closeClicked()}
                    />}
                    secondary={[
                        <HelpCallButton/>
                    ]}
                />
            </React.Fragment>}
            {this.state.stage === 'asked' && <React.Fragment>
                <UI.Message
                    appearance={'warning'}
                >
                    Match Fields feature is in Beta phase. Your feedback enhances our innovation. <a href={'https://getintio.atlassian.net/servicedesk/customer/portals'} target={'_blank'}>Contact us with suggestions</a>.
                </UI.Message>
                <UI.Message appearance={"info"}>
                    Click the Match button to automatically match fields. Once completed, you will see the results of the field matching, and you can decide which field mappings should be applied.
                </UI.Message>
                <UI.ButtonsBar
                    primary={<UI.Button
                        isLoading={this.state.isLoading}
                        onClick={() => this.matchFieldsClicked() }
                        appearance={'elementary'}
                        text={`Match`}
                    />}
                    secondary={[
                        <UI.Button
                            appearance={`secondary`}
                            text={`Close`}
                            onClick={() => this.closeClicked()}
                        />
                    ]}
                />
            </React.Fragment>}
            {this.state.stage === 'finished' && <React.Fragment>
                <UI.Message appearance={"info"}>
                    Matching fields has finished. Please review matched fields and decide how to merge them into current fields configuration
                </UI.Message>
                {(this.result && this.result.summary.entries.length > 0) && <React.Fragment>
                    <UI.Message appearance={"warning"}>
                        Please address the following mapping issues
                        <ul>
                            {this.result.summary.entries.map((e, index) => <li key={index}>
                                {e.message}
                            </li>)}
                        </ul>
                        <p>At least one of the fields is of the 'choice' type. Please click the 'cog' icon to access details, map options, or configure field mapping. Currently, the data from these fields may not sync properly.</p>
                    </UI.Message><br/>
                </React.Fragment> }
                <FieldsMappings
                    /* we should but the btn back */
                    shouldDisableAutoMap={ true }
                    disableAddFieldBtn={true}
                    appsSupport={this.props.appSupport}
                    mapping={ this.state.mapping }
                    multiFieldsUsage={this.props.multiFieldsUsage}
                    leftTrigger={this.props.leftTrigger}
                    rightTrigger={this.props.rightTrigger}
                    isMangerView={true}
                />
                <UI.ButtonsBar
                    secondary={[
                        <UI.Button
                            text={`Merge`}
                            onClick={() => this.applyProperties('merge')}
                        />,
                        <UI.Button
                            text={`Replace`}
                            onClick={() => this.applyProperties('replace')}
                        />
                    ]}
                />
            </React.Fragment>}
        </UI.FullScreenModal>
    }

    private async applyClicked(): Promise<boolean> {
        return true;
    }

    private async applyProperties(mode: 'merge' | 'replace'): Promise<void> {
        await this.props.applyHandler(this.state.mapping.propertiesConfiguration.properties, mode);
        await this.props.closeHandler();
    }

    private async closeClicked(): Promise<boolean> {
        this.props.closeHandler();
        return true;
    }

    private async reviewClicked(): Promise<void> {
        // this.props.applyHandler(this.result?.definition);
    }
}