import React, {Component, useEffect} from 'react';

import { FieldError, UI } from 'src/engrator-core';
import { FormError } from '../../../engrator-core/form-error.type';
import { TableGridData } from '../../../engrator-core/ui';

import { fetchSyncErrors } from './rest-api';
import { createSyncErrorsTableGridData } from './sync-errors-table-grid-data';
import {getParsedWindowUrl} from "../../reporting/rest-api";
import {useGlobalState} from "../../global-store";
import {MainMenuItem} from "../../global-store/create-global-state";
import {getReportingVerticalMenu} from "../../reporting/reporting-vertical-menu";

type State = {
    isLoading: boolean;
    error?: FormError;
};
type Props = {
    location: Location;
    history: any;
};

class SyncErrorsPageCmp extends Component<Props, State> {
    private tableGridData?: TableGridData;
    private totalPages: number = 0;
    private pageToFetch: number = 0;

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount(): void {
        this.setCurrentPageFromUrl();
        this.fetchErrorsFromApi();
    }

    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<State>, snapshot?: any): void {
        if ((this.props).location.search !== (prevProps as any).location.search) {
            this.setCurrentPageFromUrl();
            this.fetchErrorsFromApi();
        }
    }

    render() {
        return (
            <UI.Page>
                { this.state.error && <UI.Message appearance={"error"}>{ this.state.error?.general }</UI.Message> }
                { this.state.isLoading && <UI.Loader appearance={'darkgray'} visible={true} /> }
                { !this.state.isLoading && this.tableGridData && <div>
                    <UI.TableGrid
						className={`analysis-errors`}
						data={this.tableGridData!.data}
						headers={ this.tableGridData!.headers }
                    />
                    <UI.Pagination currentPage={ this.pageToFetch } totalPages={ this.totalPages } onPageChangeHandler={ this.onPageChangeHandler.bind(this) } />
                </div> }
            </UI.Page>
        )
    }

    private setCurrentPageFromUrl() {
        this.pageToFetch = parseInt(new URL(getParsedWindowUrl()).searchParams.get('page') as string) || 0;
    }

    private onPageChangeHandler(newPageNumber: number) {
        (this.props).history.push({
            pathname: (this.props as any).location.pathname,
            search: `?page=${ newPageNumber }`
        });
    }

    private fetchErrorsFromApi() {
        this.setState({ isLoading: true }, () => {
            fetchSyncErrors(this.pageToFetch)
                .then(response => {
                    this.tableGridData = createSyncErrorsTableGridData(response.flows);
                    this.totalPages = response.pages;
                    this.setState({isLoading: false});
                })
                .catch((error: FieldError) => {
                    this.setState({isLoading: false, error: {['general']: error.message}});
                })
        });
    }
}

export function SyncErrorsPage(props: any) {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');
    useEffect(() => {
        setMenu(getReportingVerticalMenu());
        setMainMenuItem(MainMenuItem.Reporting);
    }, []);
    return <SyncErrorsPageCmp
        history={ props.history }
        location={ props.location }
    />
}