import {ISoftwareFactory, SoftwareName} from "../../../../../software";
import React from "react";
import { AppSmartIntsSupport } from '../../app-smart-ints-support';
import { UI } from '../../../../../engrator-core';
import { SmartIntTrigger } from '../../definition/smart-int-trigger.type';
import { fetchConnection } from "src/app/integration/connections/rest-api";
import {ConnectionModel} from "../../../connections/connection-model";

type State = {
    connection?: ConnectionModel;
    isLoadingConnection: boolean;
};

type Props = {
    softwareFactory: ISoftwareFactory;
    optionSelected: (softwareName: SoftwareName) => void;
    app: SoftwareName;
    connectionId: number;
    trigger: SmartIntTrigger;
    close: () => void;
}

export class EditApp extends React.Component<Props, State> {
    private smartIntsSupport: AppSmartIntsSupport | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoadingConnection: true
        };
        this.smartIntsSupport = this.props.softwareFactory.getSoftwareByName(this.props.app)!.getSmartIntsSupport();
    }

    async componentDidMount() {
        const connection = await fetchConnection(this.props.connectionId)
        await this.setState({ isLoadingConnection: false, connection });
    }

    render() {
        return <UI.FullScreenModal
            header={`Edit App (${ this.props.app})`}
            showPrimaryBtn={false}
            primaryBtnHandler={ () => Promise.resolve(true) }
            closeBtnHandler={ () => this.props.close() }
        >
            <UI.Form>
                { this.state.isLoadingConnection && <UI.LabelValue
                    label={`Connection`}
                    value={<UI.Loader visible={true} appearance={"darkgray"}/>}
                /> }
                { this.state.connection && <div className={`connection-details`}>
                    <UI.LabelValue
                        label={`Connection`}
                        value={ <span>
                        { this.state.connection.name } (#{ this.state.connection.id })<br/>
                        <a target={`blank`} href={this.state.connection.url}>{ this.state.connection.url }</a><br/></span>
                        } />
                </div> }
                { this.smartIntsSupport?.getEditTriggerConfigurationComponent(this.props.connectionId, this.props.trigger, this.onConfigured.bind(this))}
            </UI.Form>
        </UI.FullScreenModal>
    }

    private onConfigured(configuration: SmartIntTrigger) {

    }
}
