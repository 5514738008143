import React from 'react';
import { SmartIntAppAttachmentsConfiguration } from 'src/app/integration/smartints/definition/smart-int-definition-configuration.type';
import { UI } from 'src/engrator-core';
import { SoftwareName } from 'src/software';

type Props = {
    configuration: SmartIntAppAttachmentsConfiguration;
    appName: SoftwareName;
};
type State = {};

export class AttachmentsStorageConfiguration extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            <UI.FormGroup
                label={`Configure S3 storage for ${this.props.appName}`}
                description={`Attachments syncing to ${this.props.appName} will be uploaded to S3 bucket and then inserted within comments`}
            >
            </UI.FormGroup>
            <UI.FormGroup
                label={`S3 Access Key`}
            >
                <UI.Input 
                    defaultValue={ this.props.configuration.storage.s3.key } 
                    label={``} 
                    onChange={(newValue) => this.modifyProperty('key', newValue) }
                    placeholder={`S3 Access Key`}
                    isRequired={ true }
                />
            </UI.FormGroup>
            <UI.FormGroup
                label={`S3 Secret`}
            >
                <UI.Input 
                    defaultValue={this.props.configuration.storage.s3.secret} 
                    label={``} 
                    type={'password'}
                    onChange={(newValue) => this.modifyProperty('secret', newValue) }
                    placeholder={`S3 Secret`}
                    isRequired={ true }
                />
            </UI.FormGroup>
            <UI.FormGroup
                label={`Bucket Name`}
            >
                <UI.Input
                    defaultValue={this.props.configuration.storage.s3.bucketName}
                    label={``}
                    isRequired={true}
                    onChange={(newValue) => this.modifyProperty('bucketName', newValue)}
                    placeholder={`Bucket Name`}
                />
            </UI.FormGroup>
            <UI.FormGroup
                label={`Region`}
            >
                <UI.Input
                    defaultValue={this.props.configuration.storage.s3.region}
                    label={``}
                    isRequired={true}
                    onChange={(newValue) => this.modifyProperty('region', newValue)}
                    placeholder={`Region name`}
                />
            </UI.FormGroup>
        </React.Fragment>
    }

    private modifyProperty(propertyName: 'bucketName' | 'key' | 'secret' | 'region', newValue: string) {
        this.props.configuration.storage.s3[propertyName] = newValue;
    }
}
