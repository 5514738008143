import React from 'react';
import { UI } from 'src/engrator-core';

import { SmartIntDefinitionTypeMapping } from '../../definition/smart-int-definition--type-mapping.type';
import { getDefaultHierarchySyncConfiguration } from '../../definition/smart-int-definition-configuration.type';
import { SmartIntTrigger } from '../../definition/smart-int-trigger.type';
import { AppsSupport } from '../visual-integration-designer';
import { SubtasksSync } from './hierarchy/subtasks-sync';
import { EpicsSync } from './hierarchy/epics-sync';

type Props = {
  appsSupport: AppsSupport;
  mapping: SmartIntDefinitionTypeMapping;
  leftTrigger: SmartIntTrigger;
  rightTrigger: SmartIntTrigger;
};

export class Hierarchy extends React.Component<Props> {
    constructor(props: Readonly<Props> | Props) {
        super(props);
        if (this.props.mapping.hierarchyConfiguration === undefined) {
            this.props.mapping.hierarchyConfiguration = getDefaultHierarchySyncConfiguration();
        }
    }

    render() {
        if (!this.props.appsSupport.left.isSupportingHierarchyEpics()) {
            return <UI.Message appearance="error">Sorry, unfortunately { this.props.appsSupport.leftApp } is not supporting Hierarchy synchornization</UI.Message>
        }
        if (!this.props.appsSupport.right.isSupportingHierarchyEpics()) {
            return <UI.Message appearance="error">Sorry, unfortunately { this.props.appsSupport.rightApp } is not supporting Hierarchy synchornization</UI.Message>
        }
        return <React.Fragment>
            <div className={`hierarchy`}>
              <EpicsSync
                mapping={this.props.mapping}
                appsSupport={this.props.appsSupport}
                leftTrigger={this.props.leftTrigger}
                rightTrigger={this.props.rightTrigger}
                hierarchyConfiguration={this.props.mapping.hierarchyConfiguration}
              />
              <SubtasksSync
                mapping={this.props.mapping}
                appsSupport={this.props.appsSupport}
                leftTrigger={this.props.leftTrigger}
                rightTrigger={this.props.rightTrigger}
                hierarchyConfiguration={this.props.mapping.hierarchyConfiguration}
              />
            </div>
        </React.Fragment>
    }
}
