import React from 'react';


import { UI } from 'src/engrator-core';
import { SmartIntDefinitionTypeMapping } from '../../definition/smart-int-definition--type-mapping.type';
import { SmartIntAdvancedSyncConfiguration } from '../../definition/smart-int-definition-configuration.type';
import { AppsSupport } from '../visual-integration-designer';

type Props = {
    appsSupport: AppsSupport;
    mapping: SmartIntDefinitionTypeMapping;
    syncConfiguration: SmartIntAdvancedSyncConfiguration;
};
type State = {
    syncConfiguration: SmartIntAdvancedSyncConfiguration;
}

export class AdvancedBreakingFlow extends React.Component<Props, State> {
    constructor(props: Readonly<Props> | Props) {
        super(props);
        this.state = {
            syncConfiguration: JSON.parse(JSON.stringify(this.props.syncConfiguration)),
        };
    }

    render() {
        return <div className={`advanced-sync`}>
            <UI.FormSection
                label={`Breaking Flow`}
            >
                <UI.FormGroup
                    description={`Provide logical condition which when evaluated to TRUE will break the sync flow for particular item or item and its counterpart`}
                >
                    <UI.Textarea
                        defaultValue={`${this.state.syncConfiguration.breakFlowCondition || ''}`}
                        onChange={(newValue) => this.updateBreakFlowCondition(newValue)}
                        rows={1}
                    />
                </UI.FormGroup>
            </UI.FormSection>
        </div>;
    }

    private updateBreakFlowCondition(newValue: string): void {
        const syncConfiguration = this.state.syncConfiguration;
        syncConfiguration.breakFlowCondition = newValue;
        Object.assign(this.props.syncConfiguration, syncConfiguration);
        this.setState({syncConfiguration});
    }
}
