import { axiosWrapper } from 'src/engrator-core';
import { IntegrationDetailsModel, createIntegrationDetailsModel } from './integration-details-model';
import {SmartIntDefinitionTypeMapping} from "../../smartints/definition/smart-int-definition--type-mapping.type";
import {getDefaultStatusTransitionConfiguration} from "../../smartints/definition/smart-int-definition-configuration.type";
import {FILTER_SET_NAME} from "../../smartints/visual/filtering/rest-api";

const ENDPOINT_URL = '/integration/suites';

export const fetchIntegrationDetails = (id: number): Promise<IntegrationDetailsModel> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/' + id)
            .then((data: any) => {
                const integration = createIntegrationDetailsModel(data);
                integration.configuration?.types?.forEach((typeMapping: SmartIntDefinitionTypeMapping) => {
                    if (typeMapping.statusTransitionConfiguration === undefined) {
                        typeMapping.statusTransitionConfiguration = getDefaultStatusTransitionConfiguration();
                    }
                });
                if (integration.configuration?.triggers?.left?.itemsFilters && integration.configuration?.triggers.left.itemsFilters.new) {
                    integration.configuration.triggers.left.itemsFilters[FILTER_SET_NAME.NEW] = integration.configuration.triggers.left.itemsFilters.new;
                }
                if (integration.configuration?.triggers?.right?.itemsFilters && integration.configuration?.triggers.right.itemsFilters.new) {
                    integration.configuration.triggers.right.itemsFilters[FILTER_SET_NAME.NEW] = integration.configuration.triggers.right.itemsFilters.new;
                }
                resolve(integration);
            })
            .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export async function rescheduleMigration(suiteId: number, runId: number): Promise<void>  {
    await axiosWrapper.get(ENDPOINT_URL + '/' + suiteId + '/reschedule-migration/' + runId);
}